import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "App.css";
import config from "config.json";

import Header from "components/header";
import Footer from "components/footer";
import HotlapPage from "views/hotlapPage";
import GroupPage from "views/groupPage";
import ChampionshipPage from "views/championshipPage";
import CircuitPage from "views/circuitPage";
import VehiclePage from "views/vehiclePage";
import DriverPage from "views/driverPage";
import { ThemeProvider } from "ThemeContext";

function App() {
  const title = window._env_.COMPANY_NAME;
  const version = window._env_.VMS_VERSION;
  return (
    <ThemeProvider>
      <Router>
        <Header title={title} items={config.navitems}></Header>
        <main className="App">
          <Switch>
            <Redirect exact from="/" to="/hotlapping" />
            <Route path="/hotlapping/:id?">
              <HotlapPage />
            </Route>
            <Route path="/group/:id?">
              <GroupPage />
            </Route>
            <Route path="/championships/:id?">
              <ChampionshipPage />
            </Route>
            <Route path="/circuits/:id?">
              <CircuitPage />
            </Route>
            <Route path="/vehicles/:id?">
              <VehiclePage />
            </Route>
            <Route path="/drivers/:id?">
              <DriverPage />
            </Route>
          </Switch>
        </main>
        <Footer version={version}></Footer>
      </Router>
    </ThemeProvider>
  );
}

export default App;
