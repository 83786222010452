import { useState, useEffect } from "react";
import { useDetectTheme } from "./detectThemeHook";

export const useTheme = (mode) => {
  const savedTheme = localStorage.getItem("theme");
  const detectedTheme = useDetectTheme();
  const [theme, setTheme] = useState(savedTheme || detectedTheme || "light");

  useEffect(() => {
    const body = document.body;
    body.classList.remove("light", "dark");
    switch (mode) {
      case "auto":
        body.classList.add(detectedTheme || "light");
        break;
      case "toggle":
        body.classList.add(theme);
        break;
      case "light":
        body.classList.add("light");
        break;
      case "dark":
        body.classList.add("dark");
        break;

      default:
        body.classList.add(mode);
    }
  }, [mode, detectedTheme, theme]);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  return [theme, setTheme];
};
