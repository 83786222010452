import * as format from "./formatters";

export const LapTime = {
  text: "Time",
  dataField: "lap_time_str"
};

export const BestPracLap = {
  text: "Best Prac Lap",
  dataField: "fastest_practice_lap_time_str",
  formatter: format.emptyFormatter,
  formatExtraData: "None"
};

export const BestQualLap = {
  text: "Best Qual Lap",
  dataField: "fastest_qualification_lap_time_str",
  formatter: format.emptyFormatter,
  formatExtraData: "None"
};

export const BestRaceLap = {
  text: "Best Race Lap",
  dataField: "fastest_race_lap_time_str",
  formatter: format.emptyFormatter,
  formatExtraData: "None"
};

export const FastestLap = {
  text: "Best Lap",
  dataField: "fastest_lap_time_str"
};

export const Difference = {
  text: "Difference",
  dataField: "difference_seconds",
  formatter: format.differenceFormatter
};

export const Split = sector => {
  return {
    text: `S${sector}`,
    dataField: `split${sector}_str`,
    headerClasses: "collapsable",
    classes: "collapsable"
  };
};
