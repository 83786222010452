import axios from "axios";
import { parseString } from "xml2js";
import * as R from "ramda";

export const fetchData = async (url, params, parsePath) => {
  try {
    const apiToken = window._env_.API_KEY;
    const timezoneOffset = -(new Date().getTimezoneOffset() / 60);
    const headers = {
      "Content-Type": "application/xml",
      Authorization: "SRL " + apiToken,
      Accept: "application/xml",
      "Timezone-Offset": timezoneOffset
    };
    const response = await axios.get(url, { headers: headers, params: params });

    let parseResult = {};

    parseString(response.data, (err, result) => {
      parseResult = R.pathOr([], parsePath, result);
    });

    return parseResult;
  } catch (error) {
    throw error;
  }
};

export const millisToMinutesAndSeconds = millis => {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(3);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};
