import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { rowHighlightTodayStyleClass } from "tables/columns/formatters";
import { pluck, pluckAttribute } from "utils/utils";

const Table = ({
  keyField,
  columns,
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  qualification,
  highlightDaily,
  pagination,
  sortBy,
}) => {
  const qualificationRow = qualification && {
    renderer: (row) => <p>{`Fastest Lap + ${row.qual_percentage}%: ${row.qual_time_str}`}</p>,
    onlyOneExpanding: true,
    expanded: pluckAttribute(keyField, "qual_time_str", data),
    nonExpandable: pluck(keyField, data),
    className: "row-qual",
  };

  const paginator = paginationFactory({
    page,
    sizePerPage,
    totalSize,
    sizePerPageList: [100, 250, 500, 1000],
    hidePageListOnlyOnePage: true,
  });

  return (
    <BootstrapTable
      keyField={keyField}
      columns={columns}
      data={data ?? []}
      onTableChange={onTableChange}
      noDataIndication="No laps recorded"
      rowClasses={highlightDaily && rowHighlightTodayStyleClass}
      striped
      bordered
      hover
      classes="table-sm"
      expandRow={qualificationRow}
      pagination={pagination && paginator}
      remote={
        pagination && {
          pagination: true,
          filter: true,
          sort: true,
        }
      }
      defaultSorted={
        sortBy && [
          {
            dataField: sortBy,
            order: "asc",
          },
        ]
      }
    ></BootstrapTable>
  );
};

export default Table;
