import React, { useState } from "react";
import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { FiShare2 } from "react-icons/fi";

const Share = ({ URL }) => {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    if (navigator.share) {
      navigator.share({
        title: "Share SWP Page",
        url: window.location.href
      });
    } else setShow(true);
  };

  const handleCopy = () => {
    navigator.permissions.query({ name: "clipboard-write" }).then(result => {
      if (result.state === "granted" || result.state === "prompt") {
        /* write to the clipboard now */
        navigator.clipboard.writeText(window.location.href).then(
          function() {
            /* clipboard successfully set */
            setSuccess(true);
          },
          function() {
            /* clipboard write failed */
            setSuccess(false);
          }
        );
      }
    });
  };

  return (
    <>
      <Button
        className="mb-3"
        variant="outline"
        size="sm"
        onClick={handleShow}
      >
        <FiShare2 /> Share
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <OverlayTrigger
                placement="bottom"
                trigger="click"
                delay={{ show: 250, hide: 400 }}
                overlay={props => (
                  <Tooltip
                    id="copy-success"
                    {...props}
                    show={props.show.toString()}
                  >
                    {(success && "Copied URL!") || "Copy Failed"}
                  </Tooltip>
                )}
              >
                <Button variant="outline-secondary" onClick={handleCopy}>
                  Copy URL
                </Button>
              </OverlayTrigger>
            </InputGroup.Prepend>
            <FormControl
              // placeholder={URL}
              aria-label="URL"
              aria-describedby="basic-addon1"
              readOnly
              value={window.location.href}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Share;
