export const Collapsable = column => {
  return { ...column, headerClasses: "collapsable", classes: "collapsable" };
};

export const AlignLeft = column => {
  return {
    ...column,
    style: { textAlign: "left" },
    headerStyle: { textAlign: "left" }
  };
};
