import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";

import stig from "../images/stig.jpg";

const DriverCard = ({ driver, onViewData }) => {
  if (!driver.last_visit[0]) return null;
  return (
    <Card>
      <Card.Img variant="top" src={stig} />
      <Card.Body className="text-center">
        <Card.Title>{driver.name}</Card.Title>
        <Row>
          <Col>
            <div className="text-value">{driver.class[0] ? driver.class[0] : "None"}</div>
            <small className="text-uppercase text-muted">Driver Class</small>
          </Col>
          <Col>
            <div className="text-value">{driver.laps_recorded}</div>
            <small className="text-uppercase text-muted">Laps Recorded</small>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-value">{driver.last_circuit[0] ? driver.last_circuit[0].split(",")[0] : "None"}</div>
            <small className="text-uppercase text-muted">Last Circuit</small>
          </Col>
          <Col>
            <div className="text-value">{driver.last_vehicle[0] ? driver.last_vehicle : "None"}</div>
            <small className="text-uppercase text-muted">Last Vehicle</small>
          </Col>
        </Row>
        <div className="mt-2">
          <Button variant="dark" onClick={() => onViewData(driver.id[0])}>
            View Data
          </Button>
        </div>
      </Card.Body>
      <Card.Footer>
        <small className="text-muted">Last Visit: {driver.last_visit[0] ? driver.last_visit : "None"}</small>
        <br />
        <small className="text-muted">Last Event: {driver.last_race_event[0] ? driver.last_race_event : "None"}</small>
      </Card.Footer>
    </Card>
  );
};

export default DriverCard;
