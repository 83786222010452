import { fetchData } from "utils/api";
import * as moment from "moment";

import config from "config.json";

import Repository from "./repository";

export class Group extends Repository {
  constructor() {
    super("/group_events", "/group", ["group_events", "group_event"]);
  }

  async fetchEvents() {
    const response = await fetchData(this._baseURL, this._params, this._path);

    //Append the date to each name
    response.forEach((result) => {
      let event = moment(result.start_date, config.apidateformat);
      result.name = result.name.concat(
        " (",
        event.format(window._env_.DATE_FORMAT),
        ")"
      );
    });
    return response.reverse();
  }

  async fetchResults(id) {
    const url = this._baseURL + "/" + id;

    const response = await fetchData(url, this._params, this._path);

    const results = response.map(result => ({
      ...result,
      summary: result.summary?.[0].result,
      sessions: result.sessions?.[0].session ?? [],
    }));
    return results;
  }
}
