import * as c from "./columns";

export const SummaryTable = [
  c.Position,
  c.DriverName,
  c.Collapsable(c.QualPoints),
  c.Collapsable(c.RacePoints),
  c.Collapsable(c.BestPracLap),
  c.BestQualLap,
  c.BestRaceLap,
  c.Wins,
  c.TotalPoints
];

export const PracticeTable = [
  c.PositionGenerated,
  c.DriverName,
  c.Vehicle,
  c.FastestLap
];
export const QualifyingTable = [
  ...PracticeTable,
  {
    text: "Qualification Points",
    dataField: "points"
  }
];
export const RaceTable = [
  c.PositionGenerated,
  c.DriverName,
  c.Vehicle,
  c.Collapsable(c.Difference),
  c.FastestLap,
  c.Collapsable({
    text: "Race Points",
    dataField: "points"
  }),
  c.PenaltyPoints,
  c.TotalPoints
];

export const getColumns = type => {
  switch (type) {
    case "Practice":
      return PracticeTable;

    case "Qualification":
      return QualifyingTable;

    case "Race":
      return RaceTable;
    default:
      return [];
  }
};
