import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext } from "ThemeContext";

const icons = {
  light: faMoon,
  dark: faSun,
};

const ThemePicker = ({ className }) => {
  const theme = useContext(ThemeContext);
  const icon = icons[theme.state];

  const handleThemeSwitch = () => {
    console.log(theme);
    if (theme.state === "light") theme.setState("dark");
    if (theme.state === "dark") theme.setState("light");
  };

  return (
    <span className={`theme-picker ${className || ""}`}>
      <FontAwesomeIcon icon={icon} inverse onClick={handleThemeSwitch} />
    </span>
  );
};

export default ThemePicker;
