import * as c from "./columns";

export const HotlapTable = [
  c.PositionGenerated,
  c.DriverNameWithClass,
  c.LapTime,
  c.Telemetry,
  c.Collapsable(c.Date),
  c.Collapsable(c.Vehicle),
  c.Venue
];
