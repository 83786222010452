import * as c from "./columns";

const RecordsTable = [
  c.DriverName,
  c.LapTime,
  c.Collapsable(c.Date),
  c.Telemetry,
  c.Collapsable(c.Venue)
];

export const CircuitRecordsTable = [c.AlignLeft(c.Vehicle), ...RecordsTable];
export const VehicleRecordsTable = [c.AlignLeft(c.Circuit), ...RecordsTable];
