import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
// import { Modal } from "react-bootstrap";

const Telemetry = ({ url }) => {
  // const [teleShow, setTeleShow] = useState(false);

  return (
    <a className={url ? "badge badge-dark" : "badge disabled"} href={url} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faChartLine} className={!url ? "telemetry-icon" : ""} />
    </a>

    // <div className={url ? "badge badge-dark" : "badge disabled"}>
    //   <FontAwesomeIcon
    //     icon={faChartLine}
    //     inverse={!url}
    //     onClick={() => {
    //       setTeleShow(true);
    //     }}
    //   />

    //   <Modal
    //     size="xl"
    //     show={teleShow}
    //     onHide={() => setTeleShow(false)}
    //     aria-labelledby="telemetry-modal"
    //   >
    //     <Modal.Header closeButton>
    //       <Modal.Title id="telemetry-modal">Telemetry</Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //       <iframe
    //         src={url}
    //         title="Telemetry"
    //         width="100%"
    //         height="500"
    //         frameBorder="0"
    //         marginHeight="0"
    //         marginWidth="0"
    //       />
    //     </Modal.Body>
    //   </Modal>
    // </div>
  );
};

export default Telemetry;
