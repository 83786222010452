import React, { useEffect, useRef } from "react";

import { useSubject } from "hooks/subjectHook";

import Table from "components/table";
import TableLoader from "components/tableLoader";
import ShareRow from "components/shareRow";
import EventSelect from "components/select";
import Error from "components/error";
import { VehicleRecordsTable as columns } from "tables/recordsTable";
import { Vehicle } from "repositories";

const VehiclePage = () => {
  const vehicleRepo = useRef(new Vehicle("circuit_records"));
  const [state, selectCircuitEvent] = useSubject(vehicleRepo.current);
  const { events, results, status, id, error } = state;

  return (
    <React.Fragment>
      <div className="container pt-3">
        <Error error={error} />
        <ShareRow>
          <p>Select or search for a vehicle: </p>
        </ShareRow>
        <EventSelect
          selected={id}
          events={events}
          status={status}
          onSelect={(event) => {
            selectCircuitEvent(event.id[0]);
          }}
        />
      </div>

      <div className="container pt-3">
        {status === "loading" && <TableLoader />}
        {status === "finished" && (
          <React.Fragment>
            <h5>{events.find((event) => event.id[0] === id).name}</h5>
            <Table keyField="lap_id" columns={columns} data={results.results} sortBy="circuit_name" />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default VehiclePage;
