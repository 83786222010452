import React, { useEffect, useRef } from "react";

import { useSubject } from "hooks/subjectHook";

import Table from "components/table";
import TableLoader from "components/tableLoader";
import ShareRow from "components/shareRow";
import EventSelect from "components/select";
import Error from "components/error";
import { CircuitRecordsTable as columns } from "tables/recordsTable";
import { Circuit } from "repositories";

const CircuitPage = () => {
  const circuitRepo = useRef(new Circuit("vehicle_records"));
  const customerRepo = useRef(new Circuit("customer_records", true));
  const [vehicleState, selectVehicleEvent] = useSubject(circuitRepo.current);
  const [customerState, selectCustomerEvent, onCustomerTableChange, setEvents] = useSubject(customerRepo.current, {
    status: "halt",
    sizePerPage: 99999,
  });
  const { events, results: vehicleRecords, status, id, error: vehicleError } = vehicleState;
  const { results: customerRecords, status: customerStatus, error: customerError } = customerState;

  useEffect(() => {
    if (status === "fetched") {
      setEvents(events);
    }
  }, [status, events, setEvents]);

  return (
    <React.Fragment>
      <div className="container pt-3">
        <Error error={vehicleError} />
        <Error error={customerError} />
        <ShareRow>
          <p>Select or search for a circuit: </p>
        </ShareRow>
        <EventSelect
          selected={id}
          events={events}
          status={status}
          onSelect={(event) => {
            selectVehicleEvent(event.id[0]);
            selectCustomerEvent(event.id[0]);
          }}
        />
      </div>

      <div className="container pt-3">
        {status === "loading" && <TableLoader />}
        {status === "finished" && (
          <React.Fragment>
            <h5>{events.find((event) => event.id[0] === id).name}</h5>
            <Table keyField="lap_id" columns={columns} data={vehicleRecords.results} />
          </React.Fragment>
        )}
        {customerStatus === "loading" && <TableLoader />}
        {customerStatus === "finished" && (
          <React.Fragment>
            <Table keyField="lap_id" columns={columns} data={customerRecords.results} />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default CircuitPage;
