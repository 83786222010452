import * as c from "./columns";

export const DriverLapTable = [
  c.AlignLeft(c.Circuit),
  c.AlignLeft(c.Collapsable(c.Vehicle)),
  c.Date,
  c.LapTime,
  c.Telemetry,
  c.Split(1), // Collapsable
  c.Split(2), // Collapsable
  c.Split(3) // Collapsable
];
