export const QualPoints = {
  text: "Qual Points",
  dataField: "qualification_points"
};

export const RacePoints = {
  text: "Race Points",
  dataField: "race_points"
};

export const PenaltyPoints = {
  text: "Penalty Points",
  dataField: "penalty_points"
};

export const TotalPoints = {
  text: "Total Points",
  dataField: "total_points"
};
