export function subjectReducer(state, event) {
  switch (event.type) {
    case "FETCH":
      return {
        ...state,
        status: "fetching"
      };
    case "FETCHED":
      return {
        ...state,
        status: "fetched",
        events: event.response
      };
    case "SELECTED":
      return {
        ...state,
        status: "selected",
        id: event.id
      };
    case "NOTFOUND":
      return {
        ...state,
        status: "notfound"
      };
    case "LOAD":
      return {
        ...state,
        status: "loading",
        page: event.page ?? state.page,
        sizePerPage: event.sizePerPage ?? state.sizePerPage
      };
    case "FINISH":
      return {
        ...state,
        status: "finished",
        results: event.response
      };
    case "REJECT":
      return {
        ...state,
        status: "failure",
        error: event.error
      };
    case "CANCEL":
      return {
        ...state,
        status: "idle"
      };
    default:
      return state;
  }
}

export const defaultInitialState = {
  status: "idle",
  id: 0,
  events: [],
  results: [],
  page: 1,
  sizePerPage: 100,
  error: null
};
