import React from "react";
import ContentLoader from "react-content-loader";

const TableLoader = () => (
  <ContentLoader
    height={160}
    width={400}
    speed={2}
    primaryColor="var(--loader-primary)"
    secondaryColor="var(--loader-secondary)"
  >
    <rect x="14" y="5" rx="4" ry="3" width="120" height="6" />
    <rect x="14" y="20" rx="3" ry="3" width="90" height="6" />
    <rect x="14" y="65" rx="3" ry="3" width="380" height="7" />
    <rect x="14" y="80" rx="3" ry="3" width="380" height="7" />
    <rect x="14" y="95" rx="3" ry="3" width="380" height="7" />
    <rect x="14" y="40" rx="3" ry="3" width="380" height="17" />
  </ContentLoader>
);

export default TableLoader;
