import React from "react";
import { Form, InputGroup, CardColumns, Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

import { useSubjectDriver } from "hooks/driverHook";

import DriverCard from "components/driverCard";
import Table from "components/table";
import TableLoader from "components/tableLoader";
import ShareRow from "components/shareRow";
import Error from "components/error";
import { DriverLapTable as columns } from "tables/driverTable";

const DriverPage = () => {
  const [state, name, onSubmitSearch, onChangeName, onTableChange, selectDriver] = useSubjectDriver();
  const { events, results, page, sizePerPage, status, error } = state;
  return (
    <React.Fragment>
      <div className="container pt-3">
        <Error error={error} />
        <ShareRow>
          <p>Search for a driver: </p>
        </ShareRow>
        <Form className="driver-search" onSubmit={(e) => onSubmitSearch(e)}>
          <Form.Group controlId="formDriverName">
            <InputGroup>
              <Form.Control name="driver" type="text" placeholder="Name" value={name} onChange={onChangeName} />
              <InputGroup.Append>
                <Button variant="dark" type="submit">
                  <FaSearch />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Form>
      </div>
      <div className="container pt-3">
        {status === "fetched" && (
          <CardColumns>
            {events.map((driver) => (
              <DriverCard key={driver.id} driver={driver} onViewData={(id) => selectDriver(id)}></DriverCard>
            ))}
          </CardColumns>
        )}
        {status === "notfound" && (
          <React.Fragment>
            <p>Driver not Found</p>
          </React.Fragment>
        )}
        {status === "loading" && <TableLoader></TableLoader>}
        {status === "finished" && (
          <React.Fragment>
            <h5>{results.name}</h5>
            <Table
              keyField="id"
              columns={columns}
              data={results.results}
              pagination
              page={page}
              sizePerPage={sizePerPage}
              onTableChange={onTableChange}
              totalSize={parseInt(results.lap_time_results[0])}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default DriverPage;
