import * as format from "./formatters";

export const PositionGenerated = {
  text: "Pos",
  dataField: "id",
  isDummyField: true,
  formatter: format.idFormatter
};

export const Position = {
  text: "Pos",
  dataField: "position"
};

export const DriverName = {
  text: "Driver",
  dataField: "customer_name",
  style: { textAlign: "left" },
  headerStyle: { textAlign: "left" },
  formatter: format.nameFormatter
};

export const DriverNameWithClass = {
  text: "Driver",
  dataField: "customer_name",
  style: { textAlign: "left" },
  headerStyle: { textAlign: "left" },
  formatter: format.nameWithClassFormatter
};

export const Telemetry = {
  text: "",
  dataField: "telemetry_uri",
  formatter: format.telemeteryFormatter
};

export const Date = {
  text: "Date",
  dataField: "date",
  formatter: format.dateFormatter
};

export const Vehicle = {
  text: "Vehicle",
  dataField: "vehicle_name",
  formatter: format.vehicleFormatter
};

export const Circuit = {
  text: "Circuit",
  dataField: "circuit_name",
  sort: true,
  formatter: format.circuitFormatter
};

export const Venue = {
  text: "Venue",
  dataField: "venue_name"
};
