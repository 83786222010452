import React, { useContext } from "react";
//import logo from "logo.png";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import ThemePicker from "./themePicker";
import { ThemeContext } from "ThemeContext";

const Header = ({ title, items }) => {
  let location = useLocation();
  const theme = useContext(ThemeContext);
  return (
    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Navbar.Brand>
        {/* <img
          alt=""
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        /> */}
        {title}
      </Navbar.Brand>
      {theme.mode === "toggle" && (
        <ThemePicker className="theme-picker-collapse" />
      )}
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav activeKey={location.pathname}>
          {items.map((navItem, i) => (
            <LinkContainer key={i} to={navItem.Href}>
              <Nav.Item>
                <Nav.Link href={navItem.Href}>{navItem.Name}</Nav.Link>
              </Nav.Item>
            </LinkContainer>
          ))}
        </Nav>
      </Navbar.Collapse>
      {theme.mode === "toggle" && <ThemePicker />}
    </Navbar>
  );
};

export default Header;
