import React from "react";
import Select from "react-select";

const EventSelect = ({ selected, events, status, onSelect }) => {
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      backgroundColor: "var(--bg)",
    }),

    control: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: "var(--bg)",
      borderColor: isFocused ? "var(--select-primary)" : provided.borderColor,
      color: "var(--link)",
      boxShadow: isFocused ? "0 0 0 1px var(--select-primary)" : provided.boxShadow,
      "&:hover": {
        borderColor: isFocused ? "var(--select-primary)" : provided.borderColor,
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "var(--link)",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--link)",
    }),
    option: (provided, { isDisabled, isSelected, isFocused }) => ({
      ...provided,
      backgroundColor: isSelected ? "var(--select-primary)" : isFocused ? "var(--select-secondary)" : undefined,

      ":active": {
        ...provided[":active"],
        backgroundColor: !isDisabled ? (isSelected ? "var(--select-primary)" : "var(--select-secondary)") : undefined,
      },
    }),
    input: (provided) => ({
      ...provided,
      color: "var(--text)",
    }),
  };
  return (
    <Select
      // ref={selectRef}
      value={events.find((option) => option.id[0] === selected)}
      options={events}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id[0]}
      onChange={onSelect}
      isSearchable
      placeholder={
        ((status === "fetching" || status === "fetched") && "Loading...") ||
        (events.length === 0 && "No Data") ||
        events.find((event) => event.id[0] === selected).name[0]
      }
      noOptionsMessage={(obj) => obj.inputValue + " Not Found"}
      styles={customStyles}
    />
  );
};

export default EventSelect;
