import { fetchData, millisToMinutesAndSeconds } from "utils/api";

import Repository from "./repository";

export class Hotlap extends Repository {
  constructor() {
    super("/hotlap_events", "/hotlapping");
  }

  async fetchEvents() {
    this._params = { order: "datedesc", current: "1", past: "1", future: "0" };
    this._path = ["hotlap_events", "hotlap_event"];
    const response = await fetchData(this._baseURL, this._params, this._path);
    return response;
  }

  async fetchResults(id) {
    const url = this._baseURL + "/" + id;
    this._path = ["hotlap_events"];
    const response = await fetchData(url, this._params, this._path);

    // Extract Qualification band
    let qualificationBand = response.hotlap_event?.[0]?.qualification_percentage[0];
    const results = response.sub_event.map(event => ({
      ...event,
      results: event.results[0]
        ? this.addQualification(event.results[0].result.filter(result => result.invalid[0] === "0"), qualificationBand)
        : []
    }));
    return results;
  }

  addQualification = (laps, percentage) => {
    if (laps[0] && percentage) {
      const qualTimeMS = laps[0].lap_time_ms[0] * (1 + percentage / 100);

      const index = laps.findIndex(lap => lap.lap_time_ms > qualTimeMS)

      if (index !== -1) {
        laps[index - 1].qual_time_str = millisToMinutesAndSeconds(qualTimeMS);
        laps[index - 1].qual_percentage = percentage;
      }
    }
    return laps;
  };
}
