import React from "react";

import Share from "components/share";

const ShareRow = props => {
  return (
    <div className="container-row">
      {props.children}
      <Share />
    </div>
  );
};

export default ShareRow;
