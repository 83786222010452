import * as R from "ramda";

export const trimEmptyColumns = (columns, data) => {
  return R.reject(
    column => R.isEmpty(R.reject(R.isNil, R.pluck(column.dataField, data))),
    columns
  );
};

export const pluckAttribute = (attr, filterProp, data) => {
  return pluck(attr, R.filter(R.prop(filterProp))(data));
};

export const pluck = R.pluck;
