import { fetchData } from "utils/api";

export default class Repository {
  _baseURL = "https://api.simracing.co.uk/v0.1";
  _endpoint = "/";
  _subject = "/";
  _path = [];
  _params = {};
  constructor(endpoint, subject, path = []) {
    this._baseURL = this._baseURL + endpoint;
    this._endpoint = endpoint;
    this._subject = subject;
    this._path = path;
  }

  async fetchEvents() {
    const response = await fetchData(this._baseURL, this._params, this._path);
    return response;
  }

  async fetchResults(id) {
    const url = this._baseURL + "/" + id;
    const response = await fetchData(url, this._params, this._path);
    return response;
  }
}
