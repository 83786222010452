import React from "react";

import { useSubject } from "hooks/subjectHook";

import Table from "components/table";
import TableLoader from "components/tableLoader";
import ShareRow from "components/shareRow";
import EventSelect from "components/select";
import Error from "components/error";
import { HotlapTable as columns } from "tables/hotlapTable";

import { Hotlap } from "repositories";

const HotlapPage = () => {
  const [state, selectEvent] = useSubject(new Hotlap());
  const { events, results, status, id, error } = state;
  return (
    <React.Fragment>
      <div className="container pt-3">
        <Error error={error} />
        <ShareRow>
          <p>Select or search for an event: </p>
        </ShareRow>
        <EventSelect selected={id} events={events} status={status} onSelect={(event) => selectEvent(event.id[0])} />
      </div>

      <div className="container pt-3">
        {status === "loading" && <TableLoader />}
        {status === "finished" &&
          results.map((table, i) => (
            <React.Fragment key={i}>
              <h5>{table.name}</h5>
              <Table keyField="lap_id" columns={columns} data={table.results} qualification highlightDaily></Table>
            </React.Fragment>
          ))}
      </div>
    </React.Fragment>
  );
};

export default HotlapPage;
