import React from "react";

import { useSubject } from "hooks/subjectHook";
import Table from "components/table";
import TableLoader from "components/tableLoader";
import ShareRow from "components/shareRow";
import EventSelect from "components/select";
import Error from "components/error";
import { SummaryTable, getColumns } from "tables/groupTable";
import { Group } from "repositories";

const GroupPage = () => {
  const [state, selectEvent] = useSubject(new Group());
  const { events, results, status, id, error } = state;
  return (
    <React.Fragment>
      <div className="container pt-3">
        <Error error={error} />
        <ShareRow>
          <p>Select or search for an event: </p>
        </ShareRow>
        <EventSelect
          selected={id}
          events={events}
          status={status}
          onSelect={(event) => selectEvent(event.id[0])}
        />
      </div>

      <div className="container pt-3">
        {status === "loading" && <TableLoader />}
        {status === "finished" &&
          results.map((result, i) => (
            <React.Fragment key={i}>
              <h5>Event Summary</h5>
              <Table
                keyField="customer_id"
                columns={SummaryTable}
                data={result.summary}
              />
              <hr className="mt-4" />
              <h5>All Sessions</h5>
              {result.sessions.map((session, i) => (
                <React.Fragment key={i}>
                  <h5>
                    {session.name}::{session.circuit_name}
                  </h5>
                  <Table
                    keyField="customer_id"
                    columns={getColumns(session.type[0])}
                    data={session.result}
                  />
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
      </div>
    </React.Fragment>
  );
};

export default GroupPage;
