import React, { createContext } from "react";

import { useTheme } from "hooks/themeHook";

export const ThemeContext = createContext();

export function ThemeProvider(props) {
  const mode = window._env_.THEME.toLowerCase();
  const [state, setState] = useTheme(mode);

  return (
    <ThemeContext.Provider value={{ mode, state, setState }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
