import { trimEmptyColumns } from "utils/utils";
import { HotlapTable } from "tables/hotlapTable";
import * as c from "./columns";

export const SummaryTable = [c.Position, c.DriverName];

export const RaceEventTable = [
  c.PositionGenerated,
  c.DriverName,
  c.Collapsable(c.QualPoints),
  c.Collapsable(c.RacePoints),
  c.Collapsable(c.BestPracLap),
  c.Collapsable(c.BestQualLap),
  c.BestRaceLap,
  c.Collapsable(c.Wins),
  c.TotalPoints
];

export const getSummaryColumns = data => {
  const eventColumns = trimEmptyColumns(data.events.map((event, i) => c.Round(i + 1)), data.summary);
  const best = data.max_events_counted[0];

  const columns = [
    ...SummaryTable,
    ...eventColumns,
    c.Score,
    ...(best ? [{ text: "Best " + best, dataField: "max_counted_score" }] : [])
  ];

  return columns;
};

export const getEventColumns = type => {
  switch (type) {
    case "group_event":
    case "race_event":
      return RaceEventTable;

    case "hotlap_event":
      return HotlapTable;

    default:
      return [];
  }
};
