import React from "react";
import * as moment from "moment";

import config from "config.json";
import { ClassIcon } from "components/classIcon";
import Telemetry from "components/telemetry";

export const telemeteryFormatter = cell => {
  return <Telemetry url={cell}></Telemetry>;
};

export const dateFormatter = cell => {
  let event = moment(cell, config.apidateformat);
  return event.format(window._env_.DATE_FORMAT);
};

export const idFormatter = (cell, row, rowIndex) => {
  return rowIndex + 1;
};

export const emptyFormatter = (cell, row, rowIndex, filler) => {
  if (cell && cell.toString()) {
    return cell;
  } else return filler;
};

export const differenceFormatter = (cell, row) => {
  if (cell.toString()) {
    let seconds = Math.round(cell[0] * 100) / 100;
    return `${seconds} Seconds`;
  } else {
    let laps = row.difference_laps[0];
    if (Math.abs(laps) === 1) return `${laps} Lap`;
    else return `${laps} Laps`;
  }
};

const linkFormatter = (link, data) => {
  return (
    <a className="cell-link" href={link}>
      {data}
    </a>
  );
};

export const nameFormatter = (cell, row) => {
  return linkFormatter("/drivers/" + row.customer_id, cell);
};

export const nameWithClassFormatter = (cell, row) => {
  return (
    <>
      {nameFormatter(cell, row)}
      <ClassIcon id={row.class_icon[0]} />
    </>
  );
};

export const vehicleFormatter = (cell, row) => {
  return linkFormatter("/vehicles/" + row.vehicle_id, cell);
};

export const circuitFormatter = (cell, row) => {
  return linkFormatter("/circuits/" + row.circuit_id, cell);
};

// Row Formatters

export const rowHiglightTodayStyle = row => {
  const style = {};
  let today = moment();
  let lapDate = moment(row.date, config.apidateformat);
  if (today.isSame(lapDate, "day")) {
    style.backgroundColor = "#c8e6c9";
  }
  return style;
};

export const rowHighlightTodayStyleClass = row => {
  let classes = "row-lap";
  let today = moment();
  let lapDate = moment(row.date, config.apidateformat);
  if (today.isSame(lapDate, "day")) {
    classes += "-today";
  }
  return classes;
};

// Table Heading Formatters

export const eventURLFormatter = (session) => {
  if (session.type == "group_event") {
    return linkFormatter("/group/" + session.id, session.name);
  } else if (session.type == "hotlap_event"){
    return linkFormatter("/hotlapping/" + session.id, session.name);
  }
};
