import { fetchData } from "utils/api";

import Repository from "./repository";

export class Vehicle extends Repository {
  constructor(route = "circuit_records", paginate = false) {
    super("/vehicles", "/vehicles");
    this._route = route;
    this._paginate = paginate;
  }

  async fetchEvents() {
    const path = ["vehicles", "vehicle"];
    const response = await fetchData(this._baseURL, this._params, path);
    return response;
  }

  async fetchResults(id, index = 0, count = 1000) {
    const url = this._baseURL + "/" + id;
    const path = ["vehicle"];
    const params = this._paginate ? { index, count } : {}

    const response = await fetchData(`${url}/${this._route}`, params, path);
    response.results = response.results?.[0].result ?? [];
    return response;
  }
}
