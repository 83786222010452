import React from "react";
import { Image } from "react-bootstrap";
import * as moment from "moment";

import simLogo from "../images/logo_284px_bwfaded.png";

const Footer = ({version}) => {
  return (
    <footer className="App footer mt-auto py-3" id="lap-display-footer">
      <div className="container">
        <div className="container-row">
          <div className="footer-left">
            <p>
              Venue Management System {version} – System Web Presence
              <br />
              Copyright{" "}
              <a
                className="App-link"
                href="https://www.simracing.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sim Racing Limited
              </a>{" "}
              2004-{moment().year()}
            </p>
          </div>
          <div className="footer-right">
            <a
              href="https://www.simracing.co.uk"
              className="App-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={simLogo}
                fluid
                className="footer-logo"
                alt="Sim Racing Limited"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
