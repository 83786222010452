import React, { useState, useEffect } from "react";

import { Alert } from "react-bootstrap";

const errors = {
  400: "Bad Request",
  401: "Not Authorised",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  429: "The number of page requests has been exceeded and data cannot currently be displayed. The request limit is reset at the start of every hour. Reload the page to try again.",
  500: "Internal Server Error. There was a problem fulfilling this request. Try again later or contact support",
  503: "Service Unavailable. We're temporarily offline for maintenance. Please try again later",
};

const Error = ({ error }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (error && error?.response?.status !== 403) {
      setShow(true);
    }
  }, [error]);

  if (show) {
    console.log(error);
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{`Error: ${error.response?.status ?? 500}`}</Alert.Heading>
        <p>{errors[error.response?.status ?? 500]}</p>
      </Alert>
    );
  } else return null;
};

export default Error;
