import * as format from "./formatters";

export const Wins = {
  text: "Wins",
  dataField: "race_wins"
};

export const Score = {
  text: "Total",
  dataField: "score"
};

export const Round = num => {
  return {
    text: num.toString(),
    dataField: num.toString(),
    headerClasses: "collapsable",
    classes: "collapsable",
    formatter: format.emptyFormatter,
    formatExtraData: "-"
  };
};
