import { useReducer, useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

import { subjectReducer, defaultInitialState } from "reducers/reducers";
import { Driver } from "repositories";

export const useSubjectDriver = (initialState) => {
  const [state, dispatch] = useReducer(subjectReducer, { ...defaultInitialState, ...initialState });
  let history = useHistory();
  let { id } = useParams();

  const driverRepoRef = useRef(new Driver());
  const driverRepo = driverRepoRef.current

  const [name, setName] = useState("");

  useEffect(() => {
    let cancelled = false;
    if (state.status === "idle") {
      id && dispatch({ type: "SELECTED", id: id });
    }

    if (state.status === "fetching") {
      driverRepo
        .fetchEvents(name)
        .then(response => {
          if (cancelled) return;
          if (response.filter(driver => driver.last_visit[0]).length) {
            dispatch({ type: "FETCHED", response });
          } else dispatch({ type: "NOTFOUND" });
        })
        .catch(error => {
          if (cancelled) return;
          dispatch({ type: "REJECT", error });
        });
    }

    if (state.status === "selected") {
      history.push(driverRepo._subject + "/" + state.id);
      dispatch({ type: "LOAD" });
    }

    if (state.status === "loading") {
      const currentIndex = (state.page - 1) * state.sizePerPage;
      driverRepo
        .fetchResults(state.id, currentIndex, state.sizePerPage)
        .then(response => {
          if (cancelled) return;
          if (response.results) {
            dispatch({ type: "FINISH", response });
          } else dispatch({ type: "NOTFOUND" });
        })
        .catch(error => {
          if (cancelled) return;
          dispatch({ type: "REJECT", error });
        });
    }

    return () => {
      cancelled = true;
    };
  }, [state, history, id, name, driverRepo]);

  const handleSubmitSearch = event => {
    event.preventDefault();
    dispatch({ type: "FETCH" });
  };

  const handleChangeName = event => {
    setName(event.target.value);
  };

  const handleTableChange = (type, { page, sizePerPage }) => {
    dispatch({ type: "LOAD", page, sizePerPage })
  };

  const selectDriver = id => {
    dispatch({ type: "SELECTED", id: id });
  };

  return [state, name, handleSubmitSearch, handleChangeName, handleTableChange, selectDriver];
};
