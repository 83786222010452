import { useReducer, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { subjectReducer, defaultInitialState } from "reducers/reducers";

export const useSubject = (subjectRepo, initialState) => {
  const [state, dispatch] = useReducer(subjectReducer, { ...defaultInitialState, ...initialState });
  let history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    let cancelled = false;
    if (state.status === "idle") {
      dispatch({ type: "FETCH" });
    }

    // Start fetching events
    if (state.status === "fetching") {
      subjectRepo
        .fetchEvents()
        .then(response => {
          if (cancelled) return;
          if (response[0]) {
            dispatch({ type: "FETCHED", response });
          } else dispatch({ type: "NOTFOUND" });
        })
        .catch(error => {
          if (cancelled) return;
          dispatch({ type: "REJECT", error });
        });
    }

    // Once events have been fetched, use either the url id or the id of the first event as the initially selected event
    if (state.status === "fetched") {
      const selected =
        id && state.events.find(event => event.id[0] === id)
          ? id
          : state.events[0].id[0];
      dispatch({ type: "SELECTED", id: selected });
    }

    // Kick off loading laps from the API based on the selected event
    if (state.status === "selected") {
      history.push(subjectRepo._subject + "/" + state.id);
      dispatch({ type: "LOAD" });
    }

    // Start loading laps
    if (state.status === "loading") {
      const currentIndex = (state.page - 1) * state.sizePerPage;
      subjectRepo
        .fetchResults(state.id, currentIndex, state.sizePerPage)
        .then(response => {
          if (cancelled) return;

          dispatch({ type: "FINISH", response });
        })
        .catch(error => {
          if (cancelled) return;
          dispatch({ type: "REJECT", error });
        });
    }

    return () => {
      cancelled = true;
    };
  }, [state, history, id, subjectRepo]);

  const selectEvent = id => {
    dispatch({ type: "SELECTED", id: id });
  };

  const setEvents = events => {
    dispatch({ type: "FETCHED", response: events })
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    dispatch({ type: "LOAD", page, sizePerPage })
  };

  return [state, selectEvent, handleTableChange, setEvents];
};
