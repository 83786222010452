import React from "react";

import { useSubject } from "hooks/subjectHook";

import Table from "components/table";
import TableLoader from "components/tableLoader";
import ShareRow from "components/shareRow";
import EventSelect from "components/select";
import Error from "components/error";
import { getEventColumns, getSummaryColumns } from "tables/championshipTable";
import { Championship } from "repositories";
import { eventURLFormatter } from "tables/columns/formatters";
import { type } from "ramda";

const ChampionshipPage = () => {
  const [state, selectEvent] = useSubject(new Championship());
  const { events, results, status, id, error } = state;
  return (
    <React.Fragment>
      <div className="container pt-3">
        <Error error={error} />
        <ShareRow>
          <p>Select or search for a championship: </p>
        </ShareRow>
        <EventSelect
          selected={id}
          events={events}
          status={status}
          onSelect={event => selectEvent(event.id[0])}
        />
      </div>
      <div className="container pt-3">
        {status === "loading" && <TableLoader />}
        {status === "finished" &&
          results.map((result, i) => (
            <React.Fragment key={i}>
              <h5>Championship Summary</h5>
              <Table
                keyField="customer_id"
                columns={getSummaryColumns(results[0])}
                data={result.summary}
              />
              <hr className="mt-4" />
              {result.events.map((session, i) => (
                <React.Fragment key={i}>
                  <h5 align="left">{eventURLFormatter(session)}</h5>
                  <Table
                    keyField="customer_id"
                    columns={getEventColumns(session.type[0])}
                    data={session.summary}
                  />
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
      </div>
    </React.Fragment>
  );
};

export default ChampionshipPage;
