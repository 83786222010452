import { fetchData } from "utils/api";

import Repository from "./repository";

export class Driver extends Repository {
  constructor() {
    super("/customers", "/drivers");
  }

  async fetchEvents(name) {
    this._params.name = name;
    const path = ["customers", "customer"];
    const response = await fetchData(this._baseURL, this._params, path);
    return response;
  }

  async fetchResults(driverID, index, count) {
    const url = this._baseURL + "/" + driverID + "/lap_times";
    const path = ["customer"];
    const response = await fetchData(url, { index, count }, path);
    response.results = response.results?.[0].result ?? [];
    return response;
  }
}
