import { useState, useEffect } from "react";

// Define available themes
export const colorSchemes = {
  DARK: "(prefers-color-scheme: dark)",
  LIGHT: "(prefers-color-scheme: light)",
};

export const useDetectTheme = () => {
  const [theme, setTheme] = useState(getInitialTheme());

  useEffect(() => {
    if (!window.matchMedia) {
      return;
    }

    const listener = (e) => {
      if (!e || !e.matches) {
        return;
      }
      const schemeNames = Object.keys(colorSchemes);
      for (let schemeName of schemeNames) {
        if (e.media === colorSchemes[schemeName]) {
          setTheme(schemeName.toLowerCase());
          break;
        }
      }
    };

    // Add listener for all themes
    let activeMatches = [];
    Object.keys(colorSchemes).forEach((schemeName) => {
      const mq = window.matchMedia(colorSchemes[schemeName]);
      mq.addListener(listener);
      activeMatches.push(mq);
      listener(mq);
    });

    // Remove listeners, no memory leaks
    return () => {
      activeMatches.forEach((mq) => mq.removeListener(listener));
      activeMatches = [];
    };
  }, []);

  return theme;
};

const getInitialTheme = () => {
  if (!window.matchMedia) {
    return null;
  }
  let initialTheme = null;
  Object.keys(colorSchemes).forEach((schemeName) => {
    const mq = window.matchMedia(colorSchemes[schemeName]);
    if (mq.matches) {
      initialTheme = schemeName.toLowerCase();
    }
  });
  return initialTheme;
};
