import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faUser,
  faShieldAlt,
  faMedal
} from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";

const icons = {
  gold: { icon: faUser, color: "gold" },
  silver: { icon: faUser, color: "silver" },
  bronze: { icon: faUser, color: "bronze" },
  red: { icon: faUser, color: "danger" },
  blue: { icon: faUser, color: "primary" },
  green: { icon: faUser, color: "success" },
  purple: { icon: faUser, color: "purple" },
  star: { icon: faStar, color: "secondary" },
  shield: { icon: faShieldAlt, color: "secondary" },
  rosette: { icon: faMedal, color: "secondary" },
  __default__: { icon: null, color: "secondary" }
};

export const ClassIcon = ({ id }) => {
  if (id) {
    const icon = icons[id.toLowerCase()] ?? icons.__default__;
    return (
      <Badge className="badge-class-icon" pill variant={icon.color}>
        {icon.icon ? (
          <FontAwesomeIcon icon={icon.icon} inverse />
        ) : (
          id.charAt(0)
        )}
      </Badge>
    );
  } else return null;
};
