import { fetchData } from "utils/api";

import Repository from "./repository";

export class Championship extends Repository {
  constructor() {
    super("/championships", "/championships", [
      "championships",
      "championship"
    ]);
  }

  async fetchEvents() {
    const response = await fetchData(this._baseURL, this._params, this._path);
    return response;
  }

  async fetchResults(id) {
    const url = this._baseURL + "/" + id;

    const response = await fetchData(url, this._params, this._path);
    const results = response.map(result => {
      const out = {
        ...result,
        summary: this.createSummaryTable(result.summary?.[0].result),
        events: result.events?.[0].event ?? [],
      };
      out.events = out.events.map(event => ({
        ...event,
        summary: event.type[0] === "hotlap_event"
          ? event.summary[0].result.filter(result => result.invalid[0] === "0")
          : event.summary[0].result,
      }));

      return out;
    });

    return results;
  }

  createSummaryTable = results => {
    if (!results) return [];
    return results.map(result => {
      let events = result.events[0].event;

      for (let i = 0; i < events.length; i++) {
        result[events[i].number] = events[i].score;
      }
      return result;
    });
  };
}
